.layout{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(250, 242, 242);
}

.card{
    width: 40%;
    min-width: 360px;
    max-width: 400px;
    min-height: 400px;
    background-color: #eceffae7 !important;
}

.cardContent{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 2rem;
}

.formControl {
    width: 100%;
}

.formField{
    width: 100%;
    margin-top: 1rem !important;
}