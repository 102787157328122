.ul{
    display: flex;

    >li{
        font-size: large;
        color: white;
        padding-right: 12px;

        &:hover{
            color: gainsboro;
            cursor: pointer;
        }
    }
}